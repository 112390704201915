import { render, staticRenderFns } from "./s05-revise.vue?vue&type=template&id=7cc692a4&scoped=true&"
import script from "./s05-revise.vue?vue&type=script&lang=js&"
export * from "./s05-revise.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cc692a4",
  null
  
)

export default component.exports